import { Component } from "react";


export default class KComponent extends Component {
    constructor(props) {
        super(props);

        this.showToast = this.showToast.bind(this);
        this.showToastSticky = this.showToastSticky.bind(this);
    }

    /**
     * Show a standard toast by calling props.showToast(). Toast function should be passed to this component's props.
     * @param params
     */
    showToast(params) {
        this.props.showToast(params);
    }

    showToastSticky(params) {
        if (Array.isArray(params)) {
            const stickyParams = params.map((el) => {
                const newEl = {...el}
                newEl["sticky"] = true;
                return newEl;
            });
            this.props.showToast(stickyParams);
        } else {
            params["sticky"] = true;
            this.props.showToast(params);
        }
    }
}