import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import KPage from "../KPage";

import { getMyShieldVisas, accessShield } from "../../services/publicServices";


export default class ShieldAccessPage extends KPage {
    constructor(props) {
        super(props);
        this.state = {
            requestPending: false,

            shieldVisas: [],
            shieldVisaSelected: [],
        }

        this.refreshShieldVisas = this.refreshShieldVisas.bind(this);
        this.accessShield = this.accessShield.bind(this);
    }

    componentDidMount() {
        this.refreshShieldVisas();
    }

    refreshShieldVisas() {
        this.setState({requestPending: true});
        getMyShieldVisas().then((res) => {
            if (res.status === 200) {
                this.setState({shieldVisas: res.data});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });
    }

    accessShield() {
        this.setState({requestPending: true});
        accessShield(this.state.shieldVisaSelected.planet_name).then((res) => {
            if (res.status === 200) {
                this.refreshShieldVisas();
            } else if (res.status === 401) {
                this.clearUser();
            }
            this.showToast(res.data);
            this.setState({requestPending: false});
        })
    }

    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Shield Access</h1>
                </div>

                <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                    <div style={{textAlign: "left", marginBottom: "2em"}}>
                        <p>
                            Select from the list of authorized planets and use the <b>Access Shield</b> button to
                            add yourself to the shields. Shields access will be granted for the time period listed
                            in the <b>Access Interval</b> column.
                        </p>
                    </div>


                    <div>
                        <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                            <div>
                                <Button
                                    label="Access Shield"
                                    className={"p-button"}
                                    loading={this.state.requestPending}
                                    disabled={this.state.requestPending}
                                    loadingIcon="pi pi-spin pi-spinner"
                                    style={{width: "8em"}}
                                    onClick={() => {
                                        this.accessShield();
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <DataTable
                                value={this.state.shieldVisas}
                                selection={this.state.shieldVisaSelected}
                                onSelectionChange={e => this.setState({ shieldVisaSelected: e.value })}
                                selectionMode="single"
                                responsiveLayout="scroll"
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10,20,50]}
                            >
                                <Column field={"planet_name"} header={"Planet"} filter></Column>
                                <Column field={"window.from"} header={"Valid From"}></Column>
                                <Column field={"window.until"} header={"Valid Until"}></Column>
                                <Column field={"window.interval"} header={"Interval"}></Column>
                                <Column field={"limit_count"} header={"Limit"}></Column>
                                <Column field={"usage_count"} header={"Usage Count"}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
        return this.authFilterAnd(content, null);
    }
}