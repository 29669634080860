import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";

import KPage from "../KPage";

import { getSelfInfo, getMinistriesInfo, addPayRequest } from "../../services/citizenServices";

import { parseDiscordNumbers } from "../../helpers/utilHelpers";


export default class PayrollFormPage extends KPage {
    constructor(props) {
        super(props);
        this.state = {
            requestPending: false,

            citizenInfo: null,
            ministriesInfo: null,

            citizenInfoName: "",
            citizenInfoRank: "",
            citizenInfoDept: "",
            citizenInfoMin: "",

            minSelectOptions: [],
            minSelectValue: null,
            deptSelectOptions: [],
            deptSelectValue: null,
            coName: "",

            discordNumbersText: "",
            comments: "",
        }

        this.refreshInfo = this.refreshInfo.bind(this);
        this.updateDepartmentsSelect = this.updateDepartmentsSelect.bind(this);
        this.submitPayRequest = this.submitPayRequest.bind(this);
    }

    componentDidMount() {
        this.refreshInfo();
    }

    refreshInfo() {
        this.setState({requestPending: true});
        getSelfInfo().then((citRes) => {
            if (citRes.status === 200) {
                this.setState({citizenInfo: citRes.data});
                this.setState({minSelectValue: citRes.data.ministry});

                getMinistriesInfo().then((minRes) => {
                    if (minRes.status === 200) {
                        this.setState({ministriesInfo: minRes.data});
                        this.setState({minSelectOptions: minRes.data.map((min) => min.name)});
                        this.updateDepartmentsSelect(citRes.data.ministry, minRes.data);
                        this.setState({deptSelectValue: citRes.data.department});
                    } else {
                        if (minRes.status === 401) {
                            this.clearUser();
                        }
                        this.showToast(minRes.data);
                    }
                    this.setState({requestPending: false});
                });
            } else {
                if (citRes.status === 401) {
                    this.clearUser();
                }
                this.showToast(citRes.data);
            }
            this.setState({requestPending: false});
        });
    }

    updateDepartmentsSelect(minName, minInfo) {
        this.setState({
            deptSelectOptions: minInfo.filter((min) => {
                return min.name === minName;
            })[0].departments
        });
        this.setState({
            deptSelectValue: null
        });
    }

    submitPayRequest() {
        const discNums = parseDiscordNumbers(this.state.discordNumbersText);
        if (discNums) {
            this.setState({requestPending: true});
            addPayRequest(this.state.minSelectValue, this.state.deptSelectValue, discNums, this.state.coName,
                this.state.comments).then((res) => {
                    this.showToast(res.data);
                    if (res.status === 401) {
                        this.clearUser();
                    }
                    this.setState({requestPending: false});
            });
        } else {
            this.showToast({
                severity: "error",
                summary: "Error",
                detail: "Discord numbers should be a string of comma separated integer values."
            });
        }
    }

    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Payroll Form</h1>
                </div>
                <Card style={{marginBottom: "2em", textAlign: "left", maxWidth: "40vw", margin: "0 auto"}}>
                    <div style={{textAlign: "left", marginBottom: "2em"}}>
                        <p>Welcome to the Tresario Star Kingdom Holonet-enabled Payroll Form.</p>
                    </div>
                    <div className={"p-fluid"}>
                        <h3>Citizen Info</h3>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <div><label htmlFor="name"  className="p-col-fixed" style={{width: "100%"}}>Name</label></div>
                            <InputText
                                id="name"
                                value={this.state.citizenInfo ? this.state.citizenInfo.name : ""}
                                disabled={true}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <div><label htmlFor="rank"  className="p-col-fixed" style={{width: "100%"}}>Rank</label></div>
                            <InputText
                                id="rank"
                                value={this.state.citizenInfo ? this.state.citizenInfo.rank.code : ""}
                                disabled={true}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <div><label htmlFor="infoDept"  className="p-col-fixed" style={{width: "100%"}}>Department</label></div>
                            <InputText
                                id="infoDept"
                                value={this.state.citizenInfo ? this.state.citizenInfo.department : ""}
                                disabled={true}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <div><label htmlFor="infoMin"  className="p-col-fixed" style={{width: "100%"}}>Ministry</label></div>
                            <InputText
                                id="infoMin"
                                value={this.state.citizenInfo ? this.state.citizenInfo.ministry : ""}
                                disabled={true}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                    </div>
                    <div className={"p-fluid"}>
                        <h3>Current Assignment</h3>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <div><label htmlFor="minSelect"  className="p-col-fixed" style={{width: "100%"}}>Ministry</label></div>
                            <Dropdown
                                id="minSelect"
                                options={this.state.minSelectOptions}
                                value={this.state.minSelectValue}
                                onChange={(e) => {
                                    this.setState({minSelectValue: e.value});
                                    this.updateDepartmentsSelect(e.value, this.state.ministriesInfo);
                                }}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <div><label htmlFor="deptSelect"  className="p-col-fixed" style={{width: "100%"}}>Department</label></div>
                            <Dropdown
                                id="deptSelect"
                                options={this.state.deptSelectOptions}
                                value={this.state.deptSelectValue}
                                onChange={(e) => {
                                    this.setState({deptSelectValue: e.value});
                                }}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <div><label htmlFor="co-name"  className="p-col-fixed" style={{width: "100%"}}>CO Name</label></div>
                            <InputText
                                id="co-name"
                                value={this.state.coName}
                                onChange={(e) =>{
                                    this.setState({coName: e.target.value});
                                }}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                    </div>
                    <div className={"p-fluid"}>
                        <h3>Additional Info</h3>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <div><label htmlFor="discord-numbers"  className="p-col-fixed" style={{width: "100%"}}>Discord Numbers</label></div>
                            <InputText
                                id="discord-numbers"
                                value={this.state.discordNumbersText}
                                placeholder={"111,222,333,444"}
                                onChange={(e) => {
                                    this.setState({discordNumbersText: e.target.value});
                                }}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <div><label htmlFor="comments"  className="p-col-fixed" style={{width: "100%"}}>Comments</label></div>
                            <InputText
                                id="comments"
                                value={this.state.comments}
                                onChange={(e) =>{
                                    this.setState({comments: e.target.value});
                                }}
                                style={{maxWidth: "25vw"}}
                            />
                        </div>
                    </div>
                    <div>
                        <Button
                            label="Submit"
                            className={"p-button"}
                            loading={this.state.requestPending}
                            disabled={this.state.requestPending}
                            loadingIcon="pi pi-spin pi-spinner"
                            style={{width: "6em"}}
                            onClick={() => {
                                this.submitPayRequest();
                            }}
                        />
                    </div>
                </Card>
            </div>
        );
        return this.authFilterAnd(content, ["tresarian"]);
    }
}