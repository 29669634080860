import {Button} from "primereact/button";
import {Card} from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";

import KPage from "../KPage";

import { addShieldFacilites } from "../../services/adminServices";


export default class ShieldFacilitiesPage extends KPage {
    constructor(props) {
        super(props);
        this.state = {
            requestPending: false,

            facilityIdsText: "",
        }

        this.parseFacilityIds = this.parseFacilityIds.bind(this);
        this.updateFacilIds = this.updateFacilIds.bind(this);
    }

    /**
     * Parse a string containing comma-separated numbers.
     * @param idsText {string}
     * @returns {string[]}
     */
    parseFacilityIds(idsText) {
        if (idsText.length > 0) {
            const partial_uids = idsText.replace(/\s+/g, '').split(",");
            return partial_uids.map(function (uid) {
                return `4:${uid}`;
            });
        } else {
            return [];
        }
    }

    updateFacilIds() {
        this.setState({requestPending: true});
        addShieldFacilites(this.parseFacilityIds(this.state.facilityIdsText)).then((res) => {
            if (res.status === 401) {
                this.clearUser();
            }
            this.showToast(res.data);
            this.setState({requestPending: false});
        });
    }


    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Set Shield Facilities</h1>
                </div>

                <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                    <Card style={{marginBottom: "2em", textAlign: "left", maxWidth: "40vw"}}>
                        <div className={"p-fluid"}>
                            <div className="p-field" style={{marginBottom: "1em"}}>
                                <div><label htmlFor="facil-ids"  className="p-col-fixed" style={{width: "100%"}}>Facility IDs</label></div>
                                <InputTextarea
                                    id="facil-ids"
                                    rows={10}
                                    cols={30}
                                    value={this.state.facilityIdsText}
                                    placeholder={"111,222,333,444"}
                                    onChange={(e) => {
                                        this.setState({facilityIdsText: e.target.value});
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <Button
                                label="Submit"
                                className={"p-button"}
                                loading={this.state.requestPending}
                                disabled={this.state.requestPending}
                                loadingIcon="pi pi-spin pi-spinner"
                                style={{width: "6em"}}
                                onClick={() => {
                                    this.updateFacilIds();
                                }}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        );
        return this.authFilterAnd(content, ["admin"]);
    }
}