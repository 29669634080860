import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from 'primereact/datatable';
import { InputNumber } from "primereact/inputnumber";
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';

import KPage from "../KPage";
import { getShields, getValidShieldVisas, addShieldVisas, revokeShieldVisas } from "../../services/commandServices";
import {pickerDateToString} from "../../helpers/utilHelpers";


export default class ShieldManagePage extends KPage {
    constructor(props) {
        super(props);

        this.state = {
            requestPending: false,

            formTabIndex: 0,

            newVisaHandle: "",
            userNames: [],
            filteredUserNames: [],
            newVisaFrom: undefined,
            newVisaUntil: undefined,
            newVisaLimit: 1,
            newVisaInterval: 3,
            newVisaMemo: "",
            shields: [],
            shieldsSelected: [],

            revokeVisaMemo: "",
            visasValid: [],
            visasValidSelected: [],
        }

        this.refreshShields = this.refreshShields.bind(this);
        this.refreshValidVisas = this.refreshValidVisas.bind(this);
        this.addVisas = this.addVisas.bind(this);
        this.revokeVisas = this.revokeVisas.bind(this);
    }

    componentDidMount() {
        this.getUsers();
        this.refreshShields();
        this.refreshValidVisas();
    }

    refreshShields() {
        this.setState({requestPending: true});
        getShields().then((res) => {
            if (res.status === 200) {
                this.setState({shields: res.data});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });
    }

    refreshValidVisas() {
        this.setState({requestPending: true});
        getValidShieldVisas().then((res) => {
            if (res.status === 200) {
                this.setState({visasValid: res.data});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        })
    }

    addVisas() {
        const planets = this.state.shieldsSelected.map((row) => row.planet_name);
        this.setState({requestPending: true});
        addShieldVisas( this.state.newVisaHandle, pickerDateToString(this.state.newVisaFrom),
            pickerDateToString(this.state.newVisaUntil), this.state.newVisaInterval, this.state.newVisaLimit,
            this.state.newVisaMemo, planets).then((res) => {
                if (res.status === 200) {
                    this.refreshValidVisas();
                    this.setState({
                        newVisaHandle: "",
                        newVisaMemo: "",
                        shieldsSelected: [],
                    });
                } else if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
                this.setState({requestPending: false,});
        });
    }

    revokeVisas() {
        const visas = this.state.visasValidSelected.map((row) => ({user_name: row.user_name, dest_name: row.dest_name}));
        this.setState({requestPending: true});
        revokeShieldVisas(visas, this.state.revokeVisaMemo).then((res) => {
            if (res.status === 200) {
                this.refreshValidVisas();
                this.setState({
                    revokeVisaMemo: "",
                    visasValidSelected: [],
                });
            } else if (res.status === 401) {
                this.clearUser();
            }
            this.showToast(res.data);
            this.setState({requestPending: false});
        })

    }


    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Manage Shield Access</h1>
                </div>

                <div style={{textAlign: "left", paddingLeft: "5%", paddingRight: "5%"}}>
                    <TabView
                        activeIndex={this.state.formTabIndex}
                        onTabChange={(e) => {
                            this.setState({formTabIndex: e.index});
                        }}
                    >
                        <TabPanel header="Add Visa">
                            <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="new-handle" className="p-col-fixed" style={{width: "100%"}}>Name</label></div>
                                    <AutoComplete
                                        value={this.state.newVisaHandle}
                                        suggestions={this.state.filteredUserNames}
                                        completeMethod={this.filterUserNames}
                                        onChange={(e) => {
                                            this.setState({ newVisaHandle: e.value });
                                        }}
                                        dropdown
                                        style={{width: "20%"}}
                                    />
                                </div>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="new-from" className="p-col-fixed" style={{width: "100%"}}>Valid From</label></div>
                                    <Calendar
                                        id="new-from"
                                        value={this.state.newVisaFrom}
                                        onChange={(e) => {
                                            this.setState({ newVisaFrom: e.value});
                                        }}
                                        showTime
                                        showIcon
                                        style={{width: "20%"}}
                                    />
                                </div>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="new-until" className="p-col-fixed" style={{width: "100%"}}>Valid Until</label></div>
                                    <Calendar
                                        id="new-until"
                                        value={this.state.newVisaUntil}
                                        onChange={(e) => {
                                            this.setState({ newVisaUntil: e.value});
                                        }}
                                        showTime
                                        showIcon
                                        style={{width: "20%"}}
                                    />
                                </div>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor={"new-limit"} className={"p-col-fixed"} style={{width: "100%"}}>Usage Limit</label></div>
                                    <InputNumber
                                        id={"new-limit"}
                                        value={this.state.newVisaLimit}
                                        onValueChange={(e) => {
                                            this.setState({newVisaLimit: e.value});
                                        }}
                                        showButtons
                                        mode="decimal"
                                        min={1}
                                        style={{width: "15%"}}
                                    />
                                </div>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor={"new-interval"} className={"p-col-fixed"} style={{width: "100%"}}>Access Interval</label></div>
                                    <InputNumber
                                        id={"new-interval"}
                                        value={this.state.newVisaInterval}
                                        onValueChange={(e) => {
                                            this.setState({newVisaInterval: e.value});
                                        }}
                                        showButtons
                                        mode="decimal"
                                        min={1}
                                        style={{width: "15%"}}
                                    />
                                </div>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="new-memo"  className="p-col-fixed" style={{width: "100%"}}>Memo</label></div>
                                    <InputText
                                        id="new-memo"
                                        value={this.state.newVisaMemo}
                                        onChange={(e) => {
                                            this.setState({newVisaMemo: e.target.value});
                                        }}
                                        style={{width: "40%"}}
                                    />
                                </div>
                                <div>
                                    <Button
                                        label="Submit"
                                        className={"p-button"}
                                        loading={this.state.requestPending}
                                        disabled={this.state.requestPending}
                                        loadingIcon="pi pi-spin pi-spinner"
                                        style={{width: "6em"}}
                                        onClick={() => {
                                            this.addVisas();
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    value={this.state.shields}
                                    selection={this.state.shieldsSelected}
                                    metaKeySelection={false}
                                    onSelectionChange={e => this.setState({ shieldsSelected: e.value })}
                                    selectAll
                                    showSelectAll
                                    responsiveLayout="scroll"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10,20,50]}
                                >
                                    <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                                    <Column field="planet_name" header="Planet" filter></Column>
                                    <Column field="system_name" header="System" filter></Column>
                                    <Column
                                        field="uids"
                                        header="Facility UIDs"
                                        body={(rowData) => rowData ? rowData.uids.join(", ") : ""}
                                    ></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                        <TabPanel header="Revoke Visa">
                            <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="revoke-memo"  className="p-col-fixed" style={{width: "100%"}}>Memo</label></div>
                                    <InputText
                                        id="new-memo"
                                        value={this.state.revokeVisaMemo}
                                        onChange={(e) => {
                                            this.setState({revokeVisaMemo: e.target.value});
                                        }}
                                        style={{width: "40%"}}
                                    />
                                </div>
                                <div>
                                    <Button
                                        label="Submit"
                                        className={"p-button"}
                                        loading={this.state.requestPending}
                                        disabled={this.state.requestPending}
                                        loadingIcon="pi pi-spin pi-spinner"
                                        style={{width: "6em"}}
                                        onClick={() => {
                                            this.revokeVisas();
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    value={this.state.visasValid}
                                    selection={this.state.visasValidSelected}
                                    metaKeySelection={false}
                                    onSelectionChange={e => this.setState({ visasValidSelected: e.value })}
                                    selectAll
                                    showSelectAll
                                    responsiveLayout="scroll"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10,20,50]}
                                >
                                    <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                                    <Column field={"user_name"} header={"Name"} filter></Column>
                                    <Column field={"dest_name"} header={"Planet"} filter></Column>
                                    <Column field={"window.from"} header={"Valid From"}></Column>
                                    <Column field={"window.until"} header={"Valid Until"}></Column>
                                    <Column field={"window.interval"} header={"Interval"}></Column>
                                    <Column field={"limit_count"} header={"Limit"}></Column>
                                    <Column field={"usage_count"} header={"Usage Count"}></Column>
                                    <Column field={"granted.by"} header={"Granted By"} filter></Column>
                                    <Column field={"granted.memo"} header={"Memo"} filter></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                        {/*<TabPanel header="Invalid Visas">*/}
                        {/*    <div>*/}
                        {/*        <DataTable*/}
                        {/*            value={this.state.visasValid}*/}
                        {/*            selection={this.state.visasValidSelected}*/}
                        {/*            metaKeySelection={false}*/}
                        {/*            onSelectionChange={e => this.setState({ visasValidSelected: e.value })}*/}
                        {/*            selectAll*/}
                        {/*            showSelectAll*/}
                        {/*            responsiveLayout="scroll"*/}
                        {/*            paginator*/}
                        {/*            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"*/}
                        {/*            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"*/}
                        {/*            rows={10}*/}
                        {/*            rowsPerPageOptions={[10,20,50]}*/}
                        {/*        >*/}
                        {/*            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>*/}
                        {/*            <Column field={"user_name"} header={"Name"} filter></Column>*/}
                        {/*            <Column field={"dest_name"} header={"Planet"} filter></Column>*/}
                        {/*            <Column field={"window.from"} header={"Valid From"}></Column>*/}
                        {/*            <Column field={"window.until"} header={"Valid Until"}></Column>*/}
                        {/*            <Column field={"window.interval"} header={"Interval"}></Column>*/}
                        {/*            <Column field={"limit_count"} header={"Limit"}></Column>*/}
                        {/*            <Column field={"usage_count"} header={"Usage Count"}></Column>*/}
                        {/*            <Column field={"granted.by"} header={"Granted By"} filter></Column>*/}
                        {/*            <Column field={"granted.memo"} header={"Memo"} filter></Column>*/}
                        {/*        </DataTable>*/}
                        {/*    </div>*/}
                        {/*</TabPanel>*/}
                    </TabView>
                </div>

            </div>
        );
        return this.authFilterAnd(content, ["command"]);
    }
}