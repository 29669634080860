import axios from "axios";


const k2Api = axios.create({
    baseURL: "/kalayaan2/api",
    validateStatus: null,
});

/**
 * Log out current user.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function logout() {
    return axios.request({
        url: "/auth/logout",
        method: "post",
        baseURL: "/kalayaan2"
    });
}

/**
 * Get user info of the requesting user.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getSelfUserInfo() {
    return k2Api.request({
        url: "/public/user",
        method: "get",
    });
}

export async function getMyShieldVisas() {
    return k2Api.request({
        url: "/public/shields/visa",
        method: "get",
    });
}

export async function accessShield(planet) {
    return k2Api.request({
        url: "/public/shields/visa",
        method: "post",
        data: {
            planet_name: planet,
        },
    })
}
