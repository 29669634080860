import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from 'primereact/datatable';
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from 'primereact/tabview';

import KPage from "../KPage";

import { revertPayRequest, getMatOrders, approveMatOrders, revertMatOrders } from "../../services/commandServices";
import {InputText} from "primereact/inputtext";



export default class ManageMatOrdersPage extends KPage {
    constructor(props) {
        super(props);

        this.state = {
            requestPending: false,

            formTabIndex: 0,

            statusSelectOptions: ["approve", "reject"],
            statusSelectValue: null,
            messageText: "",

            pendingOrderInfo: [],
            pendingOrderSelected: [],
            evaluatedOrderInfo: [],
            evaluatedOrderSelected: [],
            processedOrderInfo: [],
        }

        this.refreshMatOrders = this.refreshMatOrders.bind(this);
        this.evaluateOrders = this.evaluateOrders.bind(this);
        this.unevaluateOrders = this.unevaluateOrders.bind(this);
    }

    componentDidMount() {
        this.refreshMatOrders();
    }

    refreshMatOrders() {
        getMatOrders().then((res) => {
            if (res.status === 200) {
                this.setState({
                    pendingOrderInfo: res.data.filter((request) => request.approved === undefined)
                });
                this.setState({
                    evaluatedOrderInfo: res.data.filter((request) => request.approved !== undefined && request.processed.processed === false)
                });
                this.setState({
                    processedOrderInfo: res.data.filter((request) => request.approved !== undefined && request.processed.processed === true)
                });
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
        });
    }

    evaluateOrders() {
        const selectedOrders = this.state.pendingOrderSelected;
        if (selectedOrders.length > 0) {
            const orderParams = selectedOrders.map((order) => {
                return {
                    name: order.name,
                    material: order.material,
                    month: order.submitted.month,
                    year: order.submitted.year,
                }
            });
            this.setState({requestPending: true});
            approveMatOrders(this.state.statusSelectValue, this.state.messageText, orderParams).then((res) => {
                if (res.status === 200) {
                    this.setState({statusSelectValue: null});
                    this.setState({messageText: ""});
                    this.refreshMatOrders();
                } else {
                    if (res.status === 401) {
                        this.clearUser();
                    }
                }
                this.showToastSticky(res.data);
                this.setState({requestPending: false});
            })
        } else {
            this.showToast({severity: "error", summary: "Error", detail: "No request selected"});
        }
    }

    unevaluateOrders() {
        const selectedOrders = this.state.evaluatedOrderSelected;
        if (selectedOrders) {
            const orderParams = selectedOrders.map((order) => {
                return {
                    name: order.name,
                    material: order.material,
                    month: order.submitted.month,
                    year: order.submitted.year,
                }
            });
            this.setState({requestPending: true});
            revertMatOrders(orderParams).then((res) => {
                if (res.status === 200) {
                    this.refreshMatOrders();
                }
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
                this.setState({requestPending: false});
            });
        } else {
            this.showToast({severity: "error", summary: "Error", detail: "No request selected"});
        }
    }


    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Manage Materials Orders</h1>
                </div>

                <div style={{textAlign: "left", paddingLeft: "5%", paddingRight: "5%"}}>
                    <TabView
                        activeIndex={this.state.formTabIndex}
                        onTabChange={(e) => {
                            this.setState({formTabIndex: e.index});
                        }}
                    >
                        <TabPanel header="Pending">
                            <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="status-select"  className="p-col-fixed" style={{width: "100%"}}>Status</label></div>
                                        <Dropdown
                                            id={"status-select"}
                                            value={this.state.statusSelectValue}
                                            options={this.state.statusSelectOptions}
                                            onChange={(e) => {
                                                this.setState({statusSelectValue: e.value});
                                                this.setState({activitySelectDisabled: (e.value !== "approve")})
                                            }}
                                            style={{width: "15vw"}}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="message"  className="p-col-fixed" style={{width: "100%"}}>Message (visible to order submitter)</label></div>
                                        <InputText
                                            id="message"
                                            value={this.state.messageText}
                                            onChange={(e) => {
                                                this.setState({messageText: e.target.value});
                                            }}
                                            style={{width: "40%"}}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        label="Submit"
                                        className={"p-button"}
                                        loading={this.state.requestPending}
                                        disabled={this.state.requestPending}
                                        loadingIcon="pi pi-spin pi-spinner"
                                        style={{width: "6em"}}
                                        onClick={() => {
                                            this.evaluateOrders();
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    value={this.state.pendingOrderInfo}
                                    selection={this.state.pendingOrderSelected}
                                    onSelectionChange={e => this.setState({ pendingOrderSelected: e.value })}
                                    responsiveLayout="scroll"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10,20,50]}
                                >
                                    <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                                    <Column field="name" header="Name" filter></Column>
                                    <Column field="material" header="Material" filter></Column>
                                    <Column field="quantity" header="Quantity"></Column>
                                    <Column field="total_price" header="Price"></Column>
                                    <Column field="submitted.month" header="Month" filter></Column>
                                    <Column field="submitted.timestamp" header="Timestamp"></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                        <TabPanel header="Evaluated">
                            <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                                <div>
                                    <Button
                                        label="Revert"
                                        className={"p-button"}
                                        loading={this.state.requestPending}
                                        disabled={this.state.requestPending}
                                        loadingIcon="pi pi-spin pi-spinner"
                                        style={{width: "6em"}}
                                        onClick={() => {
                                            this.unevaluateOrders();
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    value={this.state.evaluatedOrderInfo}
                                    selection={this.state.evaluatedOrderSelected}
                                    onSelectionChange={e => this.setState({ evaluatedOrderSelected: e.value })}
                                    responsiveLayout="scroll"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10,20,50]}
                                >
                                    <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                                    <Column field="name" header="Name" filter></Column>
                                    <Column field="material" header="Material" filter></Column>
                                    <Column field="quantity" header="Quantity"></Column>
                                    <Column field="total_price" header="Price"></Column>
                                    <Column field="submitted.month" header="Month"></Column>
                                    <Column field="approved.by" header="Evaluator"></Column>
                                    <Column
                                        field="approved.approved"
                                        header="Approved"
                                        body={(rowData) => rowData.approved.approved ? "approved" : "rejected"}
                                    ></Column>
                                    <Column field="approved.timestamp" header="Timestamp"></Column>
                                    <Column field="approved.comments" header="Approval Comments"></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                        <TabPanel header="Processed">
                            <div>
                                <DataTable
                                    value={this.state.processedOrderInfo}
                                    responsiveLayout="scroll"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10,20,50]}
                                >
                                    <Column field="name" header="Name" filter></Column>
                                    <Column field="material" header="Material" filter></Column>
                                    <Column field="quantity" header="Quantity"></Column>
                                    <Column field="total_price" header="Price"></Column>
                                    <Column field="submitted.month" header="Month" filter></Column>
                                    <Column field="submitted.year" header="Year" filter></Column>
                                    <Column field="approved.by" header="Evaluator"></Column>
                                    <Column
                                        field="approved.approved"
                                        header="Approved"
                                        body={(rowData) => rowData.approved.approved ? "approved" : "rejected"}
                                    ></Column>
                                    <Column field="approved.timestamp" header="Approval Timestamp"></Column>
                                    <Column field="approved.comments" header="Approval Comments"></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>

            </div>
        );
        return this.authFilterAnd(content, ["command"]);
    }
}