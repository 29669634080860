import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import KPage from "../KPage";

import { parseDiscordNumbers } from "../../helpers/utilHelpers";
import { getDiscordNumbers, setDiscordNumbers } from "../../services/adminServices";


export default class DiscordNumbersPage extends KPage {
    constructor(props) {
        super(props);
        this.state = {
            requestPending: false,

            monthSelectOptions: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            monthSelectValue: null,
            yearText: "",
            discordNumbersText: "",

            discordNumbersInfo: null,
        }

        this.refreshDiscordNumbers = this.refreshDiscordNumbers.bind(this);
        this.submitNumbers = this.submitNumbers.bind(this);
    }

    componentDidMount() {
        this.refreshDiscordNumbers();
    }

    refreshDiscordNumbers() {
        this.setState({requestPending: true});
        getDiscordNumbers().then((res) => {
            if (res.status === 200) {
                this.setState({discordNumbersInfo: res.data});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });

    }

    submitNumbers() {
        const discNums = parseDiscordNumbers(this.state.discordNumbersText);
        if (discNums) {
            this.setState({requestPending: true});
            setDiscordNumbers(this.state.monthSelectValue, this.state.yearText, discNums).then((res) => {
                if (res.status === 200) {
                    this.refreshDiscordNumbers();
                } else {
                    if (res.status === 401) {
                        this.clearUser();
                    }
                }
                this.showToast(res.data);
                this.setState({requestPending: false});
            })
        } else {
            this.showToast({
                severity: "error",
                summary: "Error",
                detail: "Discord numbers should be a string of comma separated integer values."
            });
        }
    }

    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Set Discord Numbers</h1>
                </div>

                <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                    <Card style={{marginBottom: "2em", textAlign: "left", maxWidth: "40vw"}}>
                        <div className={"p-fluid"}>
                            <div className="p-field" style={{marginBottom: "1em"}}>
                                <div><label htmlFor="month-select"  className="p-col-fixed" style={{width: "100%"}}>Month</label></div>
                                <Dropdown
                                    id="month-select"
                                    options={this.state.monthSelectOptions}
                                    value={this.state.monthSelectValue}
                                    onChange={(e) => {
                                        this.setState({monthSelectValue: e.value});
                                    }}
                                    style={{maxWidth: "15vw"}}
                                />
                            </div>
                            <div className="p-field" style={{marginBottom: "1em"}}>
                                <div><label htmlFor="discord-numbers"  className="p-col-fixed" style={{width: "100%"}}>Year</label></div>
                                <InputText
                                    id="discord-numbers"
                                    value={this.state.yearText}
                                    placeholder={"202X"}
                                    onChange={(e) => {
                                        this.setState({yearText: e.target.value});
                                    }}
                                    style={{maxWidth: "15vw"}}
                                />
                            </div>
                            <div className="p-field" style={{marginBottom: "1em"}}>
                                <div><label htmlFor="discord-numbers"  className="p-col-fixed" style={{width: "100%"}}>Discord Numbers</label></div>
                                <InputText
                                    id="discord-numbers"
                                    value={this.state.discordNumbersText}
                                    placeholder={"111,222,333,444"}
                                    onChange={(e) => {
                                        this.setState({discordNumbersText: e.target.value});
                                    }}
                                    style={{maxWidth: "15vw"}}
                                />
                            </div>
                        </div>
                        <div>
                            <Button
                                label="Submit"
                                className={"p-button"}
                                loading={this.state.requestPending}
                                disabled={this.state.requestPending}
                                loadingIcon="pi pi-spin pi-spinner"
                                style={{width: "6em"}}
                                onClick={() => {
                                    this.submitNumbers();
                                }}
                            />
                        </div>
                    </Card>
                    <div>
                        <DataTable
                            value={this.state.discordNumbersInfo}
                            responsiveLayout="scroll"
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10,20,50]}
                        >
                            <Column field={"month"} header={"Month"} filter></Column>
                            <Column field={"year"} header={"Year"} filter></Column>
                            <Column
                                field={"numbers"}
                                header={"Numbers"}
                                body={(rowData) => rowData ? rowData["numbers"].join(", ") : ""}
                            ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
        return this.authFilterAnd(content, ["admin"]);
    }
}