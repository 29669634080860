import KComponent from "./KComponent";
import UnauthenticatedPage from "./pages/UnauthenticatedPage";

import { getCitizens, getUsers } from "../services/commandServices";
import UnauthorizedPage from "./pages/UnauthorizedPage";

export default class KPage extends KComponent {
    constructor(props) {
        super(props);

        this.clearUser = this.clearUser.bind(this);

        this.getUsers = this.getUsers.bind(this);
        this.getCitizens = this.getCitizens.bind(this);

        this.filterUserNames = this.filterUserNames.bind(this);

        this.authFilterAnd = this.authFilterAnd.bind(this);
        this.authFilterOr = this.authFilterOr.bind(this);
    }

    clearUser() {
        this.props.clearUserCallback();
    }

    getUsers() {
        this.setState({requestPending: true});
        getUsers().then((res) => {
            if (res.status === 200) {
                this.setState({userNames: res.data});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });
    }

    getCitizens() {
        this.setState({requestPending: true});
        getCitizens().then((res) => {
            if (res.status === 200) {
                this.setState({citizenNames: res.data});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });
    }

    filterUserNames(event) {
        setTimeout(() => {
            let filteredResults;
            if (!event.query.trim().length) {
                filteredResults = [...this.state.userNames];
            }
            else {
                filteredResults = this.state.userNames.filter((name) => {
                    return name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            this.setState({ filteredUserNames: filteredResults });
        }, 250);
    }

    /**
     * Return component if user in this.props.user has all of the given privs. Else return unauthenticated page.
     * @param component Component to return if all privs are valid.
     * @param privs {string[]} Names of privs to check.
     */
    authFilterAnd(component, privs) {
        if (this.props.user) {
            if (privs) {
                const valid = privs
                    .map((name) => this.props.user["kalayaan_privs"][name])
                    .reduce((total, el) => total && el);
                if (valid) {
                    return component;
                } else {
                    return <UnauthorizedPage />;
                }
            }
            return component;
        }
        // Else return unauthenticated page.
        return <UnauthenticatedPage />;
    }

    /**
     * Return component if user in this.props.user has any of the given privs. Else return unauthenticated page.
     * @param component Component to return if any privs are valid.
     * @param privs {string[]} Names of privs to check.
     */
    authFilterOr(component, privs) {
        if (this.props.user) {
            if (privs) {
                const valid = privs
                    .map((name) => this.props.user["kalayaan_privs"][name])
                    .reduce((total, el) => total || el);
                if (valid) {
                    return component;
                } else {
                    return <UnauthorizedPage />;
                }
            }
            return component;
        }
        // Else return unauthenticated page.
        return <UnauthenticatedPage />;
    }
}