import { Card } from "primereact/card";

import KPage from "../KPage";


export default class SwcWsInfoPage extends KPage {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
                    <h1>SWC Web Services Permissions</h1>

                    <Card style={{margin: "0 auto", marginBottom: "2em", textAlign: "left", maxWidth: "40vw"}}>
                        <div style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
                            <p>
                                Kalayaan Castle Holonet Services requests certain SWC Web Services permissions from users.
                                See the <a href={"https://www.swcombine.com/rules/?Spying_and_Sabotage#You_May_Not"}>SWC rules</a> for
                                information on how SWC WS permissions may be used by applications.
                            </p>
                        </div>
                        <h3>Basic Users</h3>
                        <ul>
                            <li>character_read: Used for retrieving information to identify users.</li>
                        </ul>
                        <h3>Citizen Users</h3>
                        <ul>
                            <li>character_read: Used for retrieving information to identify users.</li>
                            <li>character_credits, character_credits_write: Used for citizen materials purchases through the Kalayaan UI.</li>
                        </ul>
                    </Card>
                </div>
            </div>
        );
    }
}