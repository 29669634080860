import axios from "axios";


const k2Api = axios.create({
    baseURL: "/kalayaan2/api",
    validateStatus: null,
});


export async function getSelfInfo() {
    return k2Api.request({
        url: "/citizen/info",
        method: "get",
    });
}

export async function getSelfInfofields() {
    return k2Api.request({
        url: "/citizen/infofields",
        method: "get",
    });
}

export async function getMinistriesInfo() {
    return k2Api.request({
        url: "/citizen/ministries",
        method: "get",
    });
}

export async function getRanks(min, dept) {
    return k2Api.request({
        url: "/citizen/ranks",
        method: "get",
        params: {
            ministry: min,
            department: dept,
        }
    });
}

export async function getTitlesInfo() {
    return k2Api.request({
        url: "/citizen/titles",
        method: "get",
    });
}

/**
 * Add a new pay request for the requesting citizen.
 * @param assignedMin {string}
 * @param assignedDept {string}
 * @param discordNums {number[]}
 * @param coName {string}
 * @param commentsText {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function addPayRequest(assignedMin, assignedDept, discordNums, coName, commentsText) {
    return k2Api.request({
        url: "/citizen/pay-request",
        method: "post",
        data: {
            ministry: assignedMin,
            department: assignedDept,
            discord_numbers: discordNums,
            co_name: coName,
            comments: commentsText,
        }
    });
}

/**
 * Send request to Kalayaan server to update infofields in Darkness to match Kalayaan values.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function sendInfofieldsSync() {
    return k2Api.request({
        url: "/citizen/infofields",
        method: "post",
    });
}

/**
 * Get dict of materials info.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getMaterialsInfo() {
    return k2Api.request({
        url: "/citizen/materials",
        method: "get",
    });
}

export async function getMaterialsOrders() {
    return k2Api.request({
        url: "/citizen/materials/order",
        method: "get",
    });
}

/**
 * Submit new materials orders.
 * @param orders {object[]}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function addMaterialsOrder(orders) {
    return k2Api.request({
        url: "/citizen/materials/order",
        method: "post",
        data: {
            orders: orders,
        }
    })
}
