/**
 * Take a date object from a PrimeReact Calendar input element, convert to ISO string and set time zone to UTC-7.
 * @param date Date
 */
export function pickerDateToString(date) {
    return `${date.toISOString().substring(0, 16)}-07:00`;
}

/**
 * Parse a string containing comma-separated numbers.
 * @param numbersText
 * @returns {number[]|null}
 */
export function parseDiscordNumbers(numbersText) {
    if (numbersText.length > 0) {
        const numbers = numbersText.trim().split(",").map((x) => parseInt(x));
        const isNumbers = numbers
            .map((x) => Number.isInteger(x))
            .reduce((val1, val2) => val1 && val2);
        return isNumbers ? numbers : null;
    } else {
        return [];
    }
}