import logo from './logo.svg';
import './App.css';
import KDashboard from "./components/KDashboard";

import "primereact/resources/themes/saga-green/theme.css";
// import "primereact/resources/themes/bootstrap4-dark-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import "primeflex/primeflex.css";


function App() {
    return (
        <div className="App" style={{height: "100vh"}}>
            <KDashboard />
        </div>
    );
}

export default App;
