import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from 'primereact/tabview';

import KPage from "../KPage";

import { getMinistriesInfo, getTitlesInfo, getRanks } from "../../services/citizenServices";
import { addCitizen, getCitizenInfo, setCitizenInfo } from "../../services/commandServices";


export default class ManageCitizensPage extends KPage {
    constructor(props) {
        super(props);

        this.state = {
            requestPending: false,

            tabIndex: 0,

            ministriesInfo: null,

            citizenNames: [],
            manCitName: "",
            manCitInfo: null,

            minSelectOptions: [],
            minSelectValue: null,
            deptSelectOptions: [],
            deptSelectValue: null,
            rankSelectOptions: [],
            rankSelectValue: null,

            ootfSelectOptions: [],
            ootfSelectValue: null,
            sotSelectOptions: [],
            sotSelectValue: null,
            sexSelectOptions: ["male", "female"],
            sexSelectValue: null,

            userNames: [],
            filteredUserNames: [],

            newCitName: "",
        }

        this.querySelectedCitizen = this.querySelectedCitizen.bind(this);
        this.updateDepartmentSelect = this.updateDepartmentSelect.bind(this);
        this.updateRankSelect = this.updateRankSelect.bind(this);

        this.getTitles = this.getTitles.bind(this);
        this.getMinistries = this.getMinistries.bind(this);

        this.updateCitizen = this.updateCitizen.bind(this);
        this.newCitizen = this.newCitizen.bind(this);
    }

    componentDidMount() {
        this.getUsers();
        this.getCitizens();
        this.getTitles();
        this.getMinistries();
    }

    getTitles() {
        this.setState({requestPending: true});
        getTitlesInfo().then((res) => {
            if (res.status === 200) {
                const ootf = res.data.filter((title) => title.type === "ootf").map((title) => title.name);
                const sot = res.data.filter((title) => title.type === "sot").map((title) => title.name);
                this.setState({ootfSelectOptions: ootf});
                this.setState({sotSelectOptions: sot});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        })
    }

    getMinistries() {
        this.setState({requestPending: true});
        getMinistriesInfo().then((res) => {
            if (res.status === 200) {
                this.setState({ministriesInfo: res.data});
                this.setState({minSelectOptions: res.data.map((min) => min.name)});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });
    }

    updateDepartmentSelect(minName, minInfo, deptValue) {
        if (minName && minInfo) {
            const newOptions = minInfo.filter((min) => {
                return min.name === minName;
            })[0].departments;
            this.setState({deptSelectOptions: newOptions});
            const newDeptVal = deptValue ? deptValue : newOptions[0];
            this.setState({deptSelectValue: newDeptVal});
            this.updateRankSelect(minName, newDeptVal);
        } else {
            this.setState({deptSelectOptions: []});
            this.setState({rankSelectOptions: []});
        }
    }

    updateRankSelect(min, dept) {
        if (min && dept) {
            this.setState({requestPending: true});
            getRanks(min, dept).then((res) => {
                if (res.status === 200) {
                    this.setState({
                        rankSelectOptions: res.data.map((rank) => ({label: rank.rank_name, value: rank.code}))
                    });
                } else {
                    if (res.status === 401) {
                        this.clearUser();
                    }
                    this.showToast(res.data);
                }
                this.setState({requestPending: false});
            });
        } else {
            this.setState({rankSelectOptions: []});
        }
    }

    querySelectedCitizen(name) {
        this.setState({requestPending: true});
        getCitizenInfo(name).then((res) => {
            if (res.status === 200) {
                this.setState({manCitInfo: res.data});
                this.setState({minSelectValue: res.data.ministry});
                this.setState({deptSelectValue: res.data.department});
                this.setState({rankSelectValue: res.data.rank ? res.data.rank.code : null});
                this.setState({sexSelectValue: res.data.sex});
                if (res.data.titles) {
                    this.setState({ootfSelectValue: (res.data.titles.ootf ? res.data.titles.ootf : null)});
                    this.setState({sotSelectValue: (res.data.titles.sot ? res.data.titles.sot : null)});
                } else {
                    this.setState({ootfSelectValue: null});
                    this.setState({sotSelectValue: null});
                }
                this.updateDepartmentSelect(res.data.ministry, this.state.ministriesInfo, res.data.department);
            } else if (res.status === 401) {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });
    }

    updateCitizen() {
        this.setState({requestPending: true});
        setCitizenInfo(this.state.manCitName, this.state.minSelectValue, this.state.deptSelectValue,
            this.state.rankSelectValue, this.state.ootfSelectValue, this.state.sotSelectValue,
            this.state.sexSelectValue).then((res) => {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
                this.setState({requestPending: false});
        });
    }

    newCitizen() {
        this.setState({requestPending: true});
        addCitizen(this.state.newCitName).then((res) => {
            if (res.status === 200) {
                this.setState({newCitName: ""});
            } else if (res.status === 401) {
                this.clearUser();
            }
            this.showToast(res.data);
            this.setState({requestPending: false});
        });
    }


    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Manage Citizens</h1>
                </div>

                <div style={{textAlign: "left", paddingLeft: "5%", paddingRight: "5%"}}>
                    <TabView
                        activeIndex={this.state.formTabIndex}
                        onTabChange={(e) => {
                            this.setState({formTabIndex: e.index});
                        }}
                    >
                        <TabPanel header="Manage Citizen">
                            <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                                <h3>Citizen</h3>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="new-name"  className="p-col-fixed" style={{width: "100%"}}>Citizen Name</label></div>
                                    <Dropdown
                                        value={this.state.manCitName}
                                        options={this.state.citizenNames}
                                        onChange={(e) => {
                                            this.setState({ manCitName: e.value });
                                            this.querySelectedCitizen(e.value);
                                        }}
                                        style={{width: "20vw"}}
                                    />
                                </div>
                                <h3>Rank</h3>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="minSelect"  className="p-col-fixed" style={{width: "100%"}}>Ministry</label></div>
                                    <Dropdown
                                        id="minSelect"
                                        options={this.state.minSelectOptions}
                                        value={this.state.minSelectValue}
                                        onChange={(e) => {
                                            this.setState({minSelectValue: e.value});
                                            this.updateDepartmentSelect(e.value, this.state.ministriesInfo);
                                        }}
                                        style={{maxWidth: "20vw"}}
                                    />
                                </div>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="deptSelect"  className="p-col-fixed" style={{width: "100%"}}>Department</label></div>
                                    <Dropdown
                                        id="deptSelect"
                                        options={this.state.deptSelectOptions}
                                        value={this.state.deptSelectValue}
                                        onChange={(e) => {
                                            this.setState({deptSelectValue: e.value});
                                            this.updateRankSelect(this.state.minSelectValue, e.value);
                                        }}
                                        style={{maxWidth: "20vw"}}
                                    />
                                </div>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="rankSelect"  className="p-col-fixed" style={{width: "100%"}}>Rank</label></div>
                                    <Dropdown
                                        id="rankSelect"
                                        options={this.state.rankSelectOptions}
                                        value={this.state.rankSelectValue}
                                        onChange={(e) => {
                                            this.setState({rankSelectValue: e.value});
                                        }}
                                        style={{maxWidth: "20vw"}}
                                    />
                                </div>
                                <h3>Titles</h3>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="ootfSelect"  className="p-col-fixed" style={{width: "100%"}}>Order of Tresarian Freedom</label></div>
                                    <Dropdown
                                        id="ootfSelect"
                                        options={this.state.ootfSelectOptions}
                                        value={this.state.ootfSelectValue}
                                        onChange={(e) => {
                                            this.setState({ootfSelectValue: e.value});
                                        }}
                                        style={{maxWidth: "20vw"}}
                                    />
                                </div>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="sotSelect"  className="p-col-fixed" style={{width: "100%"}}>Sentinels of Tresario</label></div>
                                    <Dropdown
                                        id="ootfSelect"
                                        options={this.state.sotSelectOptions}
                                        value={this.state.sotSelectValue}
                                        onChange={(e) => {
                                            this.setState({sotSelectValue: e.value});
                                        }}
                                        style={{maxWidth: "20vw"}}
                                    />
                                </div>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="sexSelect"  className="p-col-fixed" style={{width: "100%"}}>Sex (used for title honorific)</label></div>
                                    <Dropdown
                                        id="sexSelect"
                                        options={this.state.sexSelectOptions}
                                        value={this.state.sexSelectValue}
                                        onChange={(e) => {
                                            this.setState({sexSelectValue: e.value});
                                        }}
                                        style={{maxWidth: "20vw"}}
                                    />
                                </div>
                                <div>
                                    <Button
                                        label="Submit"
                                        className={"p-button"}
                                        loading={this.state.requestPending}
                                        disabled={this.state.requestPending}
                                        loadingIcon="pi pi-spin pi-spinner"
                                        style={{width: "6em"}}
                                        onClick={() => {
                                            this.updateCitizen();
                                        }}
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Add Citizen">
                            <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div><label htmlFor="new-name"  className="p-col-fixed" style={{width: "100%"}}>Citizen Name</label></div>
                                    <AutoComplete
                                        value={this.state.newCitName}
                                        suggestions={this.state.filteredUserNames}
                                        completeMethod={this.filterUserNames}
                                        onChange={(e) => {
                                            this.setState({ newCitName: e.value });
                                        }}
                                        dropdown
                                        style={{width: "20vw"}}
                                    />
                                </div>
                                <div>
                                    <Button
                                        label="Submit"
                                        className={"p-button"}
                                        loading={this.state.requestPending}
                                        disabled={this.state.requestPending}
                                        loadingIcon="pi pi-spin pi-spinner"
                                        style={{width: "6em"}}
                                        onClick={() => {
                                            this.newCitizen();
                                        }}
                                    />
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>

            </div>
        );
        return this.authFilterAnd(content, ["command"]);
    }
}