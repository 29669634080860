import axios from "axios";


const k2Api = axios.create({
    baseURL: "/kalayaan2/api",
    validateStatus: null,
});

/**
 * Get names of Kalayaan users.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getUsers() {
    return k2Api.request({
        url: "/command/users",
        method: "get",
    });
}

/**
 * Get citizen names.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getCitizens() {
    return k2Api.request({
        url: "/command/citizens",
        method: "get",
    });
}

/**
 * @param name {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getCitizenInfo(name) {
    return k2Api.request({
        url: "/command/citizens/citizen",
        method: "get",
        params: {
            name: encodeURI(name),
        }
    })
}

/**
 * Get shields info for available planets.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getShields() {
    return k2Api.request({
        url: "/command/shields",
        method: "get",
    });
}

/**
 * Get valid shield visas.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getValidShieldVisas() {
    return k2Api.request({
        url: "/command/shields/visa",
        method: "get",
        params: {
            revoked: false,
        }
    });
}

/**
 * Get list of pay requests.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getPayRequests() {
    return k2Api.request({
        url: "/command/pay-request",
        method: "get",
    });
}

/**
 * Add new shield visas to the given planets.
 * @param handle {string} SWC handle for the visa user.
 * @param validFrom {string} Starting timestamp string.
 * @param validUntil {string} Ending timestamp string.
 * @param interval {number} Validity interval in minutes.
 * @param limit {number} Usage limit.
 * @param memoText {string} Memo text content.
 * @param planets {string[]} Planet names to grant visas to.
 * @returns {AxiosPromise<any>}
 */
export async function addShieldVisas(handle, validFrom, validUntil, interval, limit, memoText, planets) {
    return k2Api.request({
        url: "/command/shields/visa",
        method: "post",
        data: {
            visa: {
                name: handle,
                window: {
                    from: validFrom,
                    until: validUntil,
                    interval: interval * 60000,
                },
                limit_count: limit,
                granted: {
                    memo: memoText,
                },
            },
            planets: planets,
        },
    });
}

/**
 * Revoke the given shield visas.
 * @param visas Array of objects with fields user_name and dest_name.
 * @param memo {string} Message to include with revoked visa.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function revokeShieldVisas(visas, memo) {
    return k2Api.request({
        url: "/command/shields/visa-revoke",
        method: "post",
        data: {
            memo: memo,
            entries: visas
        },
    });
}

/**
 * Add a new citizen to the database.
 * @param citizenName {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function addCitizen(citizenName) {
    return k2Api.request({
        url: "/command/citizens/add",
        method: "post",
        data: {
            name: citizenName,
        }
    })
}

/**
 * Set citizen titles and rank.
 * @param citName {string}
 * @param min {string}
 * @param dept {string}
 * @param rank {string}
 * @param ootf {string}
 * @param sot {string}
 * @param sex {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function setCitizenInfo(citName, min, dept, rank, ootf, sot, sex) {
    return k2Api.request({
        url: "/command/citizens/rank",
        method: "post",
        data: {
            name: citName,
            ministry: min,
            department: dept,
            rank: rank,
            titles: {
                ootf: ootf,
                sot: sot,
            },
            sex: sex,
        }
    });
}

/**
 * Approve or reject a pay request.
 * @param name {string}
 * @param month {string}
 * @param year {string}
 * @param activity {string}
 * @param status {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function setPayRequest(name, month, year, activity, status) {
    return k2Api.request({
        url: "/command/pay-request/approve",
        method: "post",
        data: {
            name: name,
            month: month,
            year: year,
            activity_level: activity,
            status: status,
        },
    });
}

/**
 * Revert evaluated pay request.
 * @param name {string}
 * @param month {string}
 * @param year {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function revertPayRequest(name, month, year) {
    return k2Api.request({
        url: "/command/pay-request/unapprove",
        method: "post",
        data: {
            name: name,
            month: month,
            year: year,
        },
    });
}

/**
 * Get materials orders.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getMatOrders() {
    return k2Api.request({
        url: "/command/materials/order",
        method: "get",
    });
}

/**
 * Approve or reject materials order.
 * @param status {string}
 * @param comments {string}
 * @param orders {object[]}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function approveMatOrders(status, comments, orders) {
    return k2Api.request({
        url: "/command/materials/order/approve",
        method: "post",
        data: {
            status: status,
            comments: comments,
            orders: orders,
        },
    });
}

/**
 * Revert approved materials order.
 * @param orders {object[]}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function revertMatOrders(orders) {
    return k2Api.request({
        url: "/command/materials/order/unapprove",
        method: "post",
        data: {
            orders: orders,
        },
    });
}
