import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";

import KPage from "../KPage";

import { getSelfInfofields, sendInfofieldsSync } from "../../services/citizenServices";


export default class InfofieldsSyncPage extends KPage {
    constructor(props) {
        super(props);
        this.state = {
            requestPending: false,

            infofields: null,
        }

        this.getInfofields = this.getInfofields.bind(this);
        this.submitSync = this.submitSync.bind(this);
    }

    componentDidMount() {
        this.getInfofields();
    }

    getInfofields() {
        this.setState({requestPending: true});
        getSelfInfofields().then((res) => {
            if (res.status === 200) {
                this.setState({infofields: res.data});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });
    }

    submitSync() {
        this.setState({requestPending: true});
        sendInfofieldsSync().then((res) => {
            this.showToast(res.data);
            this.setState({requestPending: false});
        });
    }

    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Infofields Sync</h1>
                </div>
                <Card style={{marginBottom: "2em", textAlign: "left", maxWidth: "40vw", margin: "0 auto", }}>
                    <div className={"p-fluid"}>
                        <h3>Preview Values</h3>
                        <p>Syncing infofields will overwrite existing infofields with the values below.</p>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <InputText
                                id="info1"
                                value={this.state.infofields ? this.state.infofields.info1 : ""}
                                disabled={true}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <InputText
                                id="info2"
                                value={this.state.infofields ? this.state.infofields.info2 : ""}
                                disabled={true}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                        <div className="p-field" style={{marginBottom: "1em"}}>
                            <InputText
                                id="info3"
                                value={this.state.infofields ? this.state.infofields.info3 : ""}
                                disabled={true}
                                style={{maxWidth: "15vw"}}
                            />
                        </div>
                    </div>
                    <div>
                        <Button
                            label="Sync"
                            className={"p-button"}
                            loading={this.state.requestPending}
                            disabled={this.state.requestPending}
                            loadingIcon="pi pi-spin pi-spinner"
                            style={{width: "6em"}}
                            onClick={() => {
                                this.submitSync();
                            }}
                        />
                    </div>
                </Card>
            </div>
        );
        return this.authFilterAnd(content, ["tresarian"]);
    }
}