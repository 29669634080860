import { Component } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import SwcWsInfoPage from "./pages/SwcWsInfoPage";
import ShieldAccessPage from "./pages/ShieldAccessPage";

import PayrollFormPage from "./pages/PayrollFormPage";
import InfofieldsSyncPage from "./pages/InfofieldsSyncPage";
import MatOrderFormPage from "./pages/MatOrderFormPage";
import ManageMatOrdersPage from "./pages/ManageMatOrdersPage";

import ManageCitizensPage from "./pages/ManageCitizensPage";
import ManagePayrollPage from "./pages/ManagePayrollPage";
import ShieldManagePage from "./pages/ShieldManagePage";

import UserPrivsPage from "./pages/UserPrivsPage";
import ShieldFacilitiesPage from "./pages/ShieldFacilitiesPage";
import DiscordNumbersPage from "./pages/DiscordNumbersPage";


export default class KRouter extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <HashRouter>
                <Routes>
                    {/*Public Routes*/}
                    <Route
                        path={"/"}
                        element={
                            <LandingPage />
                        }
                    />

                    <Route
                        path={"/swc-ws-info"}
                        element={
                            <SwcWsInfoPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    <Route
                        path={"/shield-access"}
                        element={
                            <ShieldAccessPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    {/*Citizen Routes*/}
                    <Route
                        path={"/payroll-form"}
                        element={
                            <PayrollFormPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    <Route
                        path={"/infofields-sync"}
                        element={
                            <InfofieldsSyncPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    <Route
                        path={"/materials-order-form"}
                        element={
                            <MatOrderFormPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    {/*Command Routes*/}
                    <Route
                        path={"/manage-citizens"}
                        element={
                            <ManageCitizensPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    <Route
                        path={"/manage-payroll"}
                        element={
                            <ManagePayrollPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    <Route
                        path={"/manage-shield-access"}
                        element={
                            <ShieldManagePage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    <Route
                        path={"/manage-mat-orders"}
                        element={
                            <ManageMatOrdersPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    {/*Admin Routes*/}
                    <Route
                        path={"/admin-user-privs"}
                        element={
                            <UserPrivsPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    <Route
                        path={"/admin-shields"}
                        element={
                            <ShieldFacilitiesPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />

                    <Route
                        path={"/monthly-numbers"}
                        element={
                            <DiscordNumbersPage
                                user={this.props.user}
                                clearUserCallback={this.props.clearUserCallback}
                                showToast={this.props.showToast}
                            />
                        }
                    />
                </Routes>
            </HashRouter>
        );
    }
}