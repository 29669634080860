import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { TabPanel } from "primereact/tabview";
import { TabView } from "primereact/tabview";

import KPage from "../KPage";

import { getMaterialsInfo, addMaterialsOrder, getMaterialsOrders } from "../../services/citizenServices";

import { parseDiscordNumbers } from "../../helpers/utilHelpers";
import {InputNumber} from "primereact/inputnumber";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Accordion, AccordionTab} from "primereact/accordion";



export default class MatOrderFormPage extends KPage {
    constructor(props) {
        super(props);
        this.state = {
            requestPending: false,

            materialsOrders: [],

            materialsInfoDict: null,
            materialsInfoList: [],

            quantumQuantity: 0,
            quantumCost: 0,
            meleeniumQuantity: 0,
            meleeniumCost: 0,
            rudicQuantity: 0,
            rudicCost: 0,
            duracreteQuantity: 0,
            duracreteCost: 0,
            rockivoryQuantity: 0,
            rockivoryCost: 0,
            tibannagasQuantity: 0,
            tibannagasCost: 0,
            variumQuantity: 0,
            variumCost: 0,
            varmigioQuantity: 0,
            varmigioCost: 0,
            lommiteQuantity: 0,
            lommiteCost: 0,
            dureliumQuantity: 0,
            dureliumCost: 0,
            totalCost: 0,
        }

        this.loadMatsInfo = this.loadMatsInfo.bind(this);
        this.loadOrders = this.loadOrders.bind(this);
        this.clearOrder = this.clearOrder.bind(this);
        this.updateTotalCost = this.updateTotalCost.bind(this);
    }

    componentDidMount() {
        this.loadMatsInfo();
        this.loadOrders();
    }

    loadMatsInfo() {
        getMaterialsInfo().then((res) => {
            if (res.status === 200) {
                const matsDict = {}
                for (let i = 0; i < res.data.length; i++) {
                    matsDict[res.data[i].name] = res.data[i];
                }
                this.setState({materialsInfoDict: matsDict});
                this.setState({materialsInfoList: res.data});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
        })
    }

    loadOrders() {
        getMaterialsOrders().then((res) => {
            if (res.status === 200) {
                this.setState({materialsOrders: res.data});
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
        })
    }

    clearOrder() {
        this.setState({
            quantumQuantity: 0,
            quantumCost: 0,
            meleeniumQuantity: 0,
            meleeniumCost: 0,
            rudicQuantity: 0,
            rudicCost: 0,
            duracreteQuantity: 0,
            duracreteCost: 0,
            rockivoryQuantity: 0,
            rockivoryCost: 0,
            tibannagasQuantity: 0,
            tibannagasCost: 0,
            variumQuantity: 0,
            variumCost: 0,
            varmigioQuantity: 0,
            varmigioCost: 0,
            lommiteQuantity: 0,
            lommiteCost: 0,
            dureliumQuantity: 0,
            dureliumCost: 0,
            totalCost: 0,
        });
    }

    updateTotalCost(oldSingleMatCost, newSingleMatCost) {
        let newTotalCost = this.state.totalCost;
        newTotalCost = newTotalCost - oldSingleMatCost + newSingleMatCost;
        this.setState({totalCost: newTotalCost});
    }

    submitOrders() {
        const orders = [
            {
                name: "quantum",
                quantity: this.state.quantumQuantity,
            },
            {
                name: "meleenium",
                quantity: this.state.meleeniumQuantity,
            },
            {
                name: "rudic",
                quantity: this.state.rudicQuantity,
            },
            {
                name: "duracrete",
                quantity: this.state.duracreteQuantity,
            },
            {
                name: "rockivory",
                quantity: this.state.rockivoryQuantity,
            },
            {
                name: "tibannagas",
                quantity: this.state.tibannagasQuantity,
            },
            {
                name: "varium",
                quantity: this.state.variumQuantity,
            },
            {
                name: "varmigio",
                quantity: this.state.varmigioQuantity,
            },
            {
                name: "lommite",
                quantity: this.state.lommiteQuantity,
            },
            {
                name: "durelium",
                quantity: this.state.dureliumQuantity,
            },
        ]
        this.setState({requestPending: true});
        addMaterialsOrder(orders.filter((el) => el.quantity > 0)).then((res) => {
            this.showToastSticky(res.data);
            if (res.status === 200) {
                this.clearOrder();
                this.loadOrders();
            }
            if (res.status === 401) {
                this.clearUser();
            }
            this.setState({requestPending: false});
        });
    }

    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Materials Order Form</h1>
                </div>

                <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                    <div style={{textAlign: "left", marginBottom: "2em"}}>
                        <p>Welcome to the Tresario Star Kingdom Holonet-enabled Materials Order Form.</p>
                    </div>


                    <TabView
                        activeIndex={this.state.formTabIndex}
                        onTabChange={(e) => {
                            this.setState({formTabIndex: e.index});
                        }}
                    >
                        <TabPanel header="New Orders">
                            <Accordion style={{margin: "2vh auto", maxWidth: "40vw"}}>
                                <AccordionTab header="Materials Prices">
                                    <div>
                                        <DataTable
                                            value={this.state.materialsInfoList}
                                            responsiveLayout="scroll"
                                            paginator
                                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                            rows={10}
                                            rowsPerPageOptions={[10,20,50]}
                                        >
                                            <Column field={"name"} header={"Name"}></Column>
                                            <Column field={"price"} header={"Price"}></Column>
                                            <Column field={"limit"} header={"Limit"}></Column>
                                        </DataTable>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                            <Card style={{textAlign: "left", margin: "2vh auto", maxWidth: "40vw"}}>
                                <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                                    <h3>Materials Order</h3>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="quantum-input"  className="p-col-fixed" style={{width: "100%"}}>Quantum</label></div>
                                        <InputNumber
                                            inputId="quantum-input"
                                            value={this.state.quantumQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.quantumCost;
                                                const newCost = e.value * this.state.materialsInfoDict["quantum"]["price"];
                                                this.setState({
                                                    quantumQuantity: e.value,
                                                    quantumCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["quantum"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="meleenium-input"  className="p-col-fixed" style={{width: "100%"}}>Meleenium</label></div>
                                        <InputNumber
                                            inputId="meleenium-input"
                                            value={this.state.meleeniumQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.meleeniumCost;
                                                const newCost = e.value * this.state.materialsInfoDict["meleenium"]["price"];
                                                this.setState({
                                                    meleeniumQuantity: e.value,
                                                    meleeniumCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["meleenium"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="rudic-input"  className="p-col-fixed" style={{width: "100%"}}>Rudic</label></div>
                                        <InputNumber
                                            inputId="rudic-input"
                                            value={this.state.rudicQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.rudicCost;
                                                const newCost = e.value * this.state.materialsInfoDict["rudic"]["price"];
                                                this.setState({
                                                    rudicQuantity: e.value,
                                                    rudicCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["rudic"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="duracrete-input"  className="p-col-fixed" style={{width: "100%"}}>Duracrete</label></div>
                                        <InputNumber
                                            inputId="duracrete-input"
                                            value={this.state.duracreteQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.duracreteCost;
                                                const newCost = e.value * this.state.materialsInfoDict["duracrete"]["price"];
                                                this.setState({
                                                    duracreteQuantity: e.value,
                                                    duracreteCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["duracrete"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="rockivory-input"  className="p-col-fixed" style={{width: "100%"}}>Rockivory</label></div>
                                        <InputNumber
                                            inputId="rockivory-input"
                                            value={this.state.rockivoryQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.rockivoryCost;
                                                const newCost = e.value * this.state.materialsInfoDict["rockivory"]["price"];
                                                this.setState({
                                                    rockivoryQuantity: e.value,
                                                    rockivoryCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["rockivory"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="tibannagas-input"  className="p-col-fixed" style={{width: "100%"}}>Tibannagas</label></div>
                                        <InputNumber
                                            inputId="tibannagas-input"
                                            value={this.state.tibannagasQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.tibannagasCost;
                                                const newCost = e.value * this.state.materialsInfoDict["tibannagas"]["price"];
                                                this.setState({
                                                    tibannagasQuantity: e.value,
                                                    tibannagasCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["tibannagas"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="varium-input"  className="p-col-fixed" style={{width: "100%"}}>Varium</label></div>
                                        <InputNumber
                                            inputId="varium-input"
                                            value={this.state.variumQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.variumCost;
                                                const newCost = e.value * this.state.materialsInfoDict["varium"]["price"];
                                                this.setState({
                                                    variumQuantity: e.value,
                                                    variumCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["varium"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="varmigio-input"  className="p-col-fixed" style={{width: "100%"}}>Varmigio</label></div>
                                        <InputNumber
                                            inputId="varmigio-input"
                                            value={this.state.varmigioQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.varmigioCost;
                                                const newCost = e.value * this.state.materialsInfoDict["varmigio"]["price"];
                                                this.setState({
                                                    varmigioQuantity: e.value,
                                                    varmigioCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["varmigio"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="lommite-input"  className="p-col-fixed" style={{width: "100%"}}>Lommite</label></div>
                                        <InputNumber
                                            inputId="lommite-input"
                                            value={this.state.lommiteQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.lommiteCost;
                                                const newCost = e.value * this.state.materialsInfoDict["lommite"]["price"];
                                                this.setState({
                                                    lommiteQuantity: e.value,
                                                    lommiteCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["lommite"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="durelium-input"  className="p-col-fixed" style={{width: "100%"}}>Durelium</label></div>
                                        <InputNumber
                                            inputId="durelium-input"
                                            value={this.state.dureliumQuantity}
                                            style={{maxWidth: "15vw"}}
                                            onValueChange={(e) => {
                                                const currentCost = this.state.dureliumCost;
                                                const newCost = e.value * this.state.materialsInfoDict["durelium"]["price"];
                                                this.setState({
                                                    dureliumQuantity: e.value,
                                                    dureliumCost: newCost,
                                                });
                                                this.updateTotalCost(currentCost, newCost);
                                            }}
                                            mode="decimal"
                                            showButtons
                                            min={0}
                                            max={this.state.materialsInfoDict ? this.state.materialsInfoDict["durelium"]["limit"] : 0}
                                        />
                                    </div>
                                    <div className="p-field" style={{marginBottom: "1em", textAlign: "right"}}>
                                        <div><label htmlFor="total-cost"  className="p-col-fixed" style={{width: "100%"}}>Total Cost</label></div>
                                        <InputText
                                            id="total-cost"
                                            value={this.state.totalCost}
                                            disabled={true}
                                            style={{maxWidth: "10vw"}}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            label="Submit"
                                            className={"p-button"}
                                            loading={this.state.requestPending}
                                            disabled={this.state.requestPending}
                                            loadingIcon="pi pi-spin pi-spinner"
                                            style={{width: "6em"}}
                                            onClick={() => {
                                                this.submitOrders();
                                            }}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </TabPanel>
                        <TabPanel header="View Orders">
                            <div>
                                <DataTable
                                    value={this.state.materialsOrders}
                                    responsiveLayout="scroll"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10,20,50]}
                                >
                                    <Column field={"material"} header={"Material"} filter></Column>
                                    <Column field={"submitted.month"} header={"Month"} filter></Column>
                                    <Column field={"submitted.year"} header={"Year"} filter></Column>
                                    <Column field={"quantity"} header={"Quantity"}></Column>
                                    <Column field={"total_price"} header={"Price"}></Column>
                                    <Column
                                        field={"processed.processed"}
                                        header={"Processed"}
                                        body={(rowData) => rowData.processed.processed ? "true" : "false"}
                                        filter
                                    ></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        );
        return this.authFilterAnd(content, ["tresarian"]);
    }
}