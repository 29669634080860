import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from 'primereact/datatable';
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from 'primereact/tabview';

import KPage from "../KPage";

import { getPayRequests, setPayRequest, revertPayRequest } from "../../services/commandServices";



export default class ManagePayrollPage extends KPage {
    constructor(props) {
        super(props);

        this.state = {
            requestPending: false,

            formTabIndex: 0,

            statusSelectOptions: ["approve", "reject"],
            statusSelectValue: null,
            activitySelectDisabled: true,
            activitySelectOptions: ["high", "standard", "low"],
            activitySelectValue: "standard",


            pendingReqInfo: [],
            pendingReqSelected: null,
            evaluatedReqInfo: [],
            evaluatedReqSelected: null,
            processedReqInfo: [],
        }

        this.refreshPayRequests = this.refreshPayRequests.bind(this);
        this.evaluatePayRequest = this.evaluatePayRequest.bind(this);
        this.unevaluatePayRequest = this.unevaluatePayRequest.bind(this);
    }

    componentDidMount() {
        this.refreshPayRequests();
    }

    refreshPayRequests() {
        this.setState({requestPending: true});
        getPayRequests().then((res) => {
            if (res.status === 200) {
                this.setState({
                    pendingReqInfo: res.data.filter((request) => request.approved === undefined)
                });
                this.setState({
                    evaluatedReqInfo: res.data.filter((request) => request.approved !== undefined && request.processed.processed === false)
                });
                this.setState({
                    processedReqInfo: res.data.filter((request) => request.approved !== undefined && request.processed.processed === true)
                });
            } else {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });
    }

    evaluatePayRequest() {
        const request = this.state.pendingReqSelected;
        if (request) {
            this.setState({requestPending: true});
            setPayRequest(request.name, request.submitted.month, request.submitted.year, this.state.activitySelectValue,
                this.state.statusSelectValue).then((res) => {
                if (res.status === 200) {
                    this.setState({statusSelectValue: null});
                    this.setState({activitySelectDisabled: true})
                    this.setState({activitySelectValue: "standard"});
                } else {
                    if (res.status === 401) {
                        this.clearUser();
                    }
                }
                this.showToast(res.data);
                this.refreshPayRequests();
                this.setState({requestPending: false});
            })
        } else {
            this.showToast({severity: "error", summary: "Error", detail: "No request selected"});
        }
    }

    unevaluatePayRequest() {
        const request = this.state.evaluatedReqSelected;
        if (request) {
            this.setState({requestPending: true});
            revertPayRequest(request.name, request.submitted.month, request.submitted.year).then((res) => {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
                this.refreshPayRequests();
                this.setState({requestPending: false});
            });
        } else {
            this.showToast({severity: "error", summary: "Error", detail: "No request selected"});
        }
    }


    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Manage Payroll</h1>
                </div>

                <div style={{textAlign: "left", paddingLeft: "5%", paddingRight: "5%"}}>
                    <TabView
                        activeIndex={this.state.formTabIndex}
                        onTabChange={(e) => {
                            this.setState({formTabIndex: e.index});
                        }}
                    >
                        <TabPanel header="Pending">
                            <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                                <div className="p-field" style={{marginBottom: "1em"}}>
                                    <div className="p-field" style={{marginBottom: "1em"}}>
                                        <div><label htmlFor="status-select"  className="p-col-fixed" style={{width: "100%"}}>Status</label></div>
                                        <Dropdown
                                            id={"status-select"}
                                            value={this.state.statusSelectValue}
                                            options={this.state.statusSelectOptions}
                                            onChange={(e) => {
                                                this.setState({statusSelectValue: e.value});
                                                this.setState({activitySelectDisabled: (e.value !== "approve")})
                                            }}
                                            style={{width: "15vw"}}
                                        />
                                    </div>
                                    <div><label htmlFor="activity-select"  className="p-col-fixed" style={{width: "100%"}}>Activity</label></div>
                                    <Dropdown
                                        id={"activity-select"}
                                        value={this.state.activitySelectValue}
                                        options={this.state.activitySelectOptions}
                                        onChange={(e) => {
                                            this.setState({activitySelectValue: e.value});
                                        }}
                                        disabled={this.state.activitySelectDisabled}
                                        style={{width: "15vw"}}
                                    />
                                </div>
                                <div>
                                    <Button
                                        label="Submit"
                                        className={"p-button"}
                                        loading={this.state.requestPending}
                                        disabled={this.state.requestPending}
                                        loadingIcon="pi pi-spin pi-spinner"
                                        style={{width: "6em"}}
                                        onClick={() => {
                                            this.evaluatePayRequest();
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    value={this.state.pendingReqInfo}
                                    selection={this.state.pendingReqSelected}
                                    onSelectionChange={e => this.setState({ pendingReqSelected: e.value })}
                                    responsiveLayout="scroll"
                                    selectionMode="single"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10,20,50]}
                                >
                                    <Column field="name" header="Name" filter></Column>
                                    <Column field="rank.code" header="Rank"></Column>
                                    <Column field="rank.department" header="Department"></Column>
                                    <Column field="assignment.department" header="Assigned Department"></Column>
                                    <Column field="co_name" header="CO"></Column>
                                    <Column field="submitted.month" header="Month"></Column>
                                    <Column field="submitted.timestamp" header="Timestamp"></Column>
                                    <Column field="submitted.comments" header="Comments"></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                        <TabPanel header="Evaluated">
                            <div className={"p-fluid"} style={{marginBottom: "2em"}}>
                                <div>
                                    <Button
                                        label="Revert"
                                        className={"p-button"}
                                        loading={this.state.requestPending}
                                        disabled={this.state.requestPending}
                                        loadingIcon="pi pi-spin pi-spinner"
                                        style={{width: "6em"}}
                                        onClick={() => {
                                            this.unevaluatePayRequest();
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    value={this.state.evaluatedReqInfo}
                                    selection={this.state.evaluatedReqSelected}
                                    onSelectionChange={e => this.setState({ evaluatedReqSelected: e.value })}
                                    responsiveLayout="scroll"
                                    selectionMode="single"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10,20,50]}
                                >
                                    <Column field="name" header="Name" filter></Column>
                                    <Column field="rank.code" header="Rank"></Column>
                                    <Column field="rank.department" header="Department"></Column>
                                    <Column field="assignment.department" header="Assigned Department"></Column>
                                    <Column field="co_name" header="CO"></Column>
                                    <Column field="submitted.month" header="Month"></Column>
                                    {/*<Column field="submitted.timestamp" header="Timestamp"></Column>*/}
                                    <Column field="submitted.comments" header="Comments"></Column>
                                    <Column field="approved.by" header="Evaluator"></Column>
                                    <Column
                                        field="approved.approved"
                                        header="Approved"
                                        body={(rowData) => rowData.approved.approved ? "approved" : "rejected"}
                                    ></Column>
                                    <Column field="approved.activity_level" header="Activity"></Column>
                                    <Column field="approved.timestamp" header="Timestamp"></Column>
                                    <Column field="approved.comments" header="Approval Comments"></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                        <TabPanel header="Processed">
                            <div>
                                <DataTable
                                    value={this.state.processedReqInfo}
                                    selection={this.state.processedReqSelected}
                                    onSelectionChange={e => this.setState({ processedReqSelected: e.value })}
                                    responsiveLayout="scroll"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10,20,50]}
                                >
                                    <Column field="name" header="Name" filter></Column>
                                    <Column field="rank.code" header="Rank"></Column>
                                    <Column field="rank.department" header="Department"></Column>
                                    <Column field="assignment.department" header="Assigned Department"></Column>
                                    <Column field="co_name" header="CO"></Column>
                                    <Column field="submitted.month" header="Month"></Column>
                                    {/*<Column field="submitted.timestamp" header="Timestamp"></Column>*/}
                                    <Column field="submitted.comments" header="Comments"></Column>
                                    <Column field="approved.by" header="Evaluator"></Column>
                                    <Column
                                        field="approved.approved"
                                        header="Approved"
                                        body={(rowData) => rowData.approved.approved ? "approved" : "rejected"}
                                    ></Column>
                                    <Column field="approved.activity_level" header="Activity"></Column>
                                    {/*<Column field="approved.timestamp" header="Timestamp"></Column>*/}
                                    <Column field="approved.comments" header="Approval Comments"></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>

            </div>
        );
        return this.authFilterAnd(content, ["command"]);
    }
}