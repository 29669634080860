import { Component } from "react";


export default class UnauthorizedPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>Unauthorized</h1>
                </div>

                <div style={{textAlign: "left", paddingLeft: "5%", paddingRight: "5%"}}>
                    <p>Missing required privileges.</p>
                </div>

            </div>
        );
    }
}