import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

import KPage from "../KPage";

import { getUserInfo, setUserPrivs } from "../../services/adminServices";


export default class UserPrivsPage extends KPage {
    constructor(props) {
        super(props);
        this.state = {
            requestPending: false,

            userNames: [],
            userSelectValue: null,

            adminValue: false,
            commandValue: false,
            homValue: false,
            tresarianValue: false,
            payroll_approveValue: false,
        }

        this.querySelectedUser = this.querySelectedUser.bind(this);
    }

    componentDidMount() {
        this.getUsers();
    }

    querySelectedUser(name) {
        this.setState({requestPending: true});
        getUserInfo(name).then((res) => {
            if (res.status === 200) {
                const privs = res.data.kalayaan_privs;
                if (privs) {
                    this.setState({adminValue: !!privs.admin});
                    this.setState({commandValue: !!privs.command});
                    this.setState({homValue: !!privs.hom});
                    this.setState({tresarianValue: !!privs.tresarian});
                    this.setState({payroll_approveValue: !!privs.payroll_approve});
                }
            } else if (res.status === 401) {
                this.clearUser();
            }
            this.setState({requestPending: false});
        });
    }

    updatePrivs() {
        this.setState({requestPending: true});
        setUserPrivs(this.state.userSelectValue, this.state.adminValue, this.state.commandValue, this.state.homValue,
            this.state.tresarianValue, this.state.payroll_approveValue).then((res) => {
                if (res.status === 401) {
                    this.clearUser();
                }
                this.showToast(res.data);
                this.setState({requestPending: false});
        });
    }

    render() {
        const content = (
            <div style={{ width: "90%", minHeight: "100vh", margin: "0 auto", paddingTop: "1%",
                backgroundColor: "var(--surface-300)" }}>

                <div style={{textAlign: "center"}}>
                    <h1>User Admin</h1>
                </div>

                <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                    <Card style={{marginBottom: "2em", textAlign: "left", maxWidth: "40vw"}}>
                        <div className={"p-fluid"} style={{marginBottom: "1em"}}>
                            <div className="p-field" style={{marginBottom: "1em"}}>
                                <div><label htmlFor="new-name"  className="p-col-fixed" style={{width: "100%"}}>User</label></div>
                                <Dropdown
                                    value={this.state.userSelectValue}
                                    options={this.state.userNames}
                                    onChange={(e) => {
                                        this.setState({userSelectValue: e.value});
                                        this.querySelectedUser(e.value);
                                    }}
                                    style={{width: "15vw"}}
                                />
                            </div>
                            <div className="p-col-12 p-field" style={{marginBottom: "0.5em"}}>
                                <Checkbox
                                    inputId="admin-cb"
                                    value={true}
                                    onChange={(e) => {
                                        this.setState({adminValue: e.checked});
                                    }}
                                    checked={this.state.adminValue}
                                ></Checkbox>
                                <label htmlFor="admin-cb" className="p-checkbox-label" style={{marginLeft: "0.5em"}}>admin</label>
                            </div>
                            <div className="p-col-12 p-field" style={{marginBottom: "0.5em"}}>
                                <Checkbox
                                    inputId="command-cb"
                                    value={true}
                                    onChange={(e) => {
                                        this.setState({commandValue: e.checked});
                                    }}
                                    checked={this.state.commandValue}
                                ></Checkbox>
                                <label htmlFor="command-cb" className="p-checkbox-label" style={{marginLeft: "0.5em"}}>command</label>
                            </div>
                            <div className="p-col-12 p-field" style={{marginBottom: "0.5em"}}>
                                <Checkbox
                                    inputId="hom-cb"
                                    value={true}
                                    onChange={(e) => {
                                        this.setState({homValue: e.checked});
                                    }}
                                    checked={this.state.homValue}
                                ></Checkbox>
                                <label htmlFor="hom-cb" className="p-checkbox-label" style={{marginLeft: "0.5em"}}>hom</label>
                            </div>
                            <div className="p-col-12 p-field" style={{marginBottom: "0.5em"}}>
                                <Checkbox
                                    inputId="tresarian-cb"
                                    value={true}
                                    onChange={(e) => {
                                        this.setState({tresarianValue: e.checked});
                                    }}
                                    checked={this.state.tresarianValue}
                                ></Checkbox>
                                <label htmlFor="tresarian-cb" className="p-checkbox-label" style={{marginLeft: "0.5em"}}>tresarian</label>
                            </div>
                            <div className="p-col-12 p-field" style={{marginBottom: "0.5em"}}>
                                <Checkbox
                                    inputId="payroll-approve-cb"
                                    value={true}
                                    onChange={(e) => {
                                        this.setState({payroll_approveValue: e.checked});
                                    }}
                                    checked={this.state.payroll_approveValue}
                                ></Checkbox>
                                <label htmlFor="payroll-approve-cb" className="p-checkbox-label" style={{marginLeft: "0.5em"}}>payroll_approve</label>
                            </div>
                        </div>
                        <div>
                            <Button
                                label="Submit"
                                className={"p-button"}
                                loading={this.state.requestPending}
                                disabled={this.state.requestPending}
                                loadingIcon="pi pi-spin pi-spinner"
                                style={{width: "6em"}}
                                onClick={() => {
                                    this.updatePrivs();
                                }}
                            />
                        </div>
                    </Card>
                </div>


            </div>
        );
        return this.authFilterAnd(content, ["admin"]);
    }
}