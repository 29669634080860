import axios from "axios";


const k2Api = axios.create({
    baseURL: "/kalayaan2/api",
    validateStatus: null,
});

/**
 * Get Kalayaan user info.
 * @param name {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getUserInfo(name) {
    return k2Api.request({
        url: "/admin/user/info",
        params: {
            handle: name,
        }
    });
}

/**
 * Set Kalayaan privs to the given values.
 * @param name {string}
 * @param adminValue {boolean}
 * @param commandValue {boolean}
 * @param homValue {boolean}
 * @param tresarianValue {boolean}
 * @param payroll_approveValue {boolean}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function setUserPrivs(name, adminValue, commandValue, homValue, tresarianValue, payroll_approveValue) {
    return k2Api.request({
        url: "/admin/user/privs",
        method: "post",
        data: {
            name: name,
            privs: {
                admin: adminValue,
                command: commandValue,
                hom: homValue,
                tresarian: tresarianValue,
                payroll_approve: payroll_approveValue,
            }
        },
    });
}

/**
 * Add/set shield facilities.
 * @param full_uids {string[]}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function addShieldFacilites(full_uids) {
    return k2Api.request({
        url: "/admin/shields/add",
        method: "post",
        data: {
            uids: full_uids,
        },
    });
}

/**
 * Get discord number records.
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getDiscordNumbers() {
    return k2Api.request({
        url: "/admin/discord-nums",
        method: "get",
    });
}

/**
 * Set discord numbers.
 * @param month {string}
 * @param year {number}
 * @param numbers {number[]}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function setDiscordNumbers(month, year, numbers) {
    return k2Api.request({
        url: "/admin/discord-nums",
        method: "post",
        data: {
            month: month,
            year: year,
            numbers: numbers,
        }
    });
}
