import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Menubar } from "primereact/menubar";
import { Sidebar } from "primereact/sidebar";


import KComponent from "./KComponent";
import { logout } from "../services/publicServices";


export default class KMenubar extends KComponent {
    constructor(props) {
        super(props);
        this.state = {
            userSidebarVisible: false,
        }
    }

    render() {
        return (
            <div>
                <Menubar
                    model={[
                        {
                            label: "Home",
                            command:()=>{
                                window.location.hash="/";
                            }
                        },
                        {
                            label: "Public",
                            disabled: !this.props.user,
                            items: [
                                {
                                    label: "Shield Access",
                                    command:()=>{
                                        window.location.hash="/shield-access";
                                    }
                                },
                            ]
                        },
                        {
                            label: "Citizen",
                            disabled: this.props.user ? !this.props.user["kalayaan_privs"]["tresarian"] : true,
                            items: [
                                {
                                    label: "Payroll Form",
                                    command:()=>{
                                        window.location.hash="/payroll-form";
                                    }
                                },
                                {
                                    label: "Infofields Sync",
                                    command:()=>{
                                        window.location.hash="/infofields-sync";
                                    }
                                },
                                {
                                    label: "Materials Order Form",
                                    command:()=>{
                                        window.location.hash="/materials-order-form";
                                    }
                                },
                            ]
                        },
                        {
                            label: "Command",
                            disabled: this.props.user ? !this.props.user["kalayaan_privs"]["command"] : true,
                            items: [
                                {
                                    label: "Manage Citizens",
                                    command:()=>{
                                        window.location.hash="/manage-citizens";
                                    }
                                },
                                {
                                    label: "Manage Payroll",
                                    command:()=>{
                                        window.location.hash="/manage-payroll";
                                    }
                                },
                                {
                                    label: "Manage Shield Access",
                                    command:()=>{
                                        window.location.hash="/manage-shield-access";
                                    }
                                },
                                {
                                    label: "Manage Material Orders",
                                    command:()=>{
                                        window.location.hash="/manage-mat-orders";
                                    }
                                },
                            ]
                        },
                        {
                            label: "Admin",
                            disabled: this.props.user ? !this.props.user["kalayaan_privs"]["admin"] : true,
                            items: [
                                {
                                    label: "User Privs",
                                    command:()=>{
                                        window.location.hash="/admin-user-privs";
                                    }
                                },
                                {
                                    label: "Shield Facilities",
                                    command:()=>{
                                        window.location.hash="/admin-shields";
                                    }
                                },
                                {
                                    label: "Monthly Numbers",
                                    command:()=>{
                                        window.location.hash="/monthly-numbers";
                                    }
                                },
                            ]
                        }
                    ]}
                    end={<Button
                        label={this.props.user ? this.props.user.name : "Log In"}
                        icon="pi pi-user"
                        className="p-button-rounded p-button-info p-button-outlined"
                        onClick={() => {
                            this.setState({userSidebarVisible: true});
                        }}
                    />}
                />
                <Sidebar
                    visible={this.state.userSidebarVisible}
                    position="right"
                    className="p-sidebar-sm"
                    onHide={() => {
                        this.setState({userSidebarVisible: false});
                    }}
                >
                    <div>Log in via SWC Web Services</div>
                    <Divider />
                    <Button
                        label={"Basic Login"}
                        className={"p-button p-py-1"}
                        onClick={() => {
                            window.location = `https://${window.location.host}/kalayaan2/auth/swcombine/`;
                        }}
                        style={{width: "100%"}}
                        disabled={this.props.user}
                    />
                    <Divider />
                    <Button
                        label={"Citizen Login"}
                        className={"p-button p-py-1"}
                        onClick={() => {
                            window.location = `https://${window.location.host}/kalayaan2/auth/swcombine/tsk/`;
                        }}
                        style={{width: "100%"}}
                        disabled={this.props.user}
                    />
                    <Divider />
                    <Button
                        label={"Command Login"}
                        className={"p-button p-py-1"}
                        onClick={() => {
                            window.location = `https://${window.location.host}/kalayaan2/auth/swcombine/tsk/command/`;
                        }}
                        style={{width: "100%"}}
                        disabled={this.props.user}
                    />
                    <Divider />
                    <Button
                        label={"Logout"}
                        className={"p-button p-py-1"}
                        onClick={() => {
                            logout().then((res) => {
                                this.props.clearUserCallback();
                                this.showToast(res.data);
                            });
                        }}
                        style={{width: "100%"}}
                        disabled={!this.props.user}
                    />
                    <Divider />
                    <p>Kalayaan Castle Holonet Services uses <a href={"/kalayaan2/#/swc-ws-info"}>these</a> SWC Web Services permissions.</p>
                </Sidebar>
            </div>
        )
    }
}
