import { Component } from "react";

import { Toast } from "primereact/toast";

import KMenubar from "./KMenubar";
import KRouter from "./KRouter";

import { getSelfUserInfo } from "../services/publicServices";


export default class KDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            requestPending: false,
        };

        this.clearUserCallback = this.clearUserCallback.bind(this);

        this.showToast = this.showToast.bind(this);
    }

    /**
     * Clear the user field from this component's state.
     */
    clearUserCallback() {
        this.setState({user: null});
    }

    /**
     * Show a standard toast.
     * @param params Toast params.
     */
    showToast(params) {
        this.toast.show(params);
    }

    componentDidMount() {
        this.setState({requestPending: true});
        getSelfUserInfo().then((res) => {
            if (res.status === 200) {
                this.setState({user: res.data});
            } else {
                this.showToast(res.data);
            }
            this.setState({requestPending: false});
        });
    }

    render() {
        return(
            <div style={{minHeight: "100vh"}}>
                <Toast ref={(el) => this.toast = el} />
                <KMenubar
                    user={this.state.user}
                    clearUserCallback={this.clearUserCallback}
                    showToast={this.showToast}
                />
                <KRouter
                    user={this.state.user}
                    clearUserCallback={this.clearUserCallback}
                    showToast={this.showToast}
                />
            </div>
        );
    }
}